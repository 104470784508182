.form {
  display: grid;
  width: 100%;
  grid-template-columns:  repeat( auto-fit, minmax(250px, 1fr) );
  grid-gap: 16px;
  padding: 20px;

}

.block {
  grid-column: 1/ -1;
  margin: 0 0 -16px;
  font-weight: 600;
}

.agreement {
  grid-column: 1/ -1;
}

.covid {
  grid-column: 1/ -1;
}

.covidBooleanSelect {
  @media screen and (min-width: 600px){
    grid-column: auto / span 2;
  }
}
