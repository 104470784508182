.form {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  outline: 0;
  background: white;
  max-height: 100vh;
  overflow: auto;

  @media screen and (min-width: 600px) {
    border-radius: 5px;
    max-width: calc(100vw - 40px);

    > * {
      padding: 40px !important;
    }
  }

  @media screen and (min-width: 1240px) {
    max-width: 1200px;
  }
}

.buttons {
  background: white;
  position: sticky;
  bottom: 20px;
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;

  &:after{
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    width: 100%;
    height: calc(100% + 30px);
    background: white;
    z-index: -1;
    pointer-events: none;
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;

    > * {
      flex: 0 1 50%;
    }

  }

  > * {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}
