.section {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1640px;

  h3 {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
  }


  &.steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 170px;
    padding-bottom: 170px;

    color: #222222;

    .step {
      max-width: 350px;
      position: relative;
      padding: 50px 0;

      &:before {
        position: absolute;
        top: 50%;
        content: "";
        display: block;
        width: 120px;
        height: 120px;

        background-size: 115px;
        background-position: center;
        background-repeat: no-repeat;
      }

      .step-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.33;
        margin-bottom: 20px;
      }

      .step-info-text {
        font-size: 18px;
        line-height: 1.33;
      }

      &:nth-of-type(1) {
        &:before {
          background-image: url("../images/ic_notification_hell.svg");
        }
      }
      &:nth-of-type(2) {
        &:before {
          background-image: url("../images/ic_drags_of_our_family.svg");
        }
      }
      &:nth-of-type(3) {
        &:before {
          background-image: url("../images/ic_jesus.svg");
        }
      }


      &:nth-of-type(2n+1) {
        text-align: right;
        transform: translate(calc(-50% - 120px));

        &:before {
          right: -120px;
          transform: translate(50%, -50%);
        }
      }

      &:nth-of-type(2n) {
        text-align: left;
        transform: translate(calc(50% + 120px));

        &:before {
          left: -120px;
          transform: translate(-50%, -50%);
        }
      }

    }

    @media screen and (max-width: 600px) {

      padding-top: 70px;
      padding-bottom: 70px;

      .step{
        &:before {
          left: 50% !important;
          transform: translate(-50%, 0) !important;
          top: 15px !important;
        }

        transform: none !important;
        max-width: 100%;
        text-align: center !important;
        padding: 20px;
        padding-top: 160px;
      }

    }
  }

  &.what-is{


    .section_left {

      @media screen and (min-width: 1200px) {
        background-position-y: 25%;
        background-size: 300%;
        background-image: url("../images/shutterstock_1165708858.png");
      }

      //.section_content-subtitle span {
      //  color: #1a1a1a;
      //}
    }


    .download {
      color: var(--light-blue-grey);

      @media screen and (min-width: 1200px) {
        color: var(--deepBlue);
      }

      &:before{
        content: "";
        display: inline-block;
        width: 40px;
        height: 40px;
        background-image: url("../images/pdf.svg");

        margin-bottom: -6px;
        background-size: 40px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        text-decoration: underline;
      }
    }


    @media screen and (max-width: 1200px) {
      background-image: url("../images/shutterstock_1165708858.png");
      background-position: top;
      background-size: 300%;
    }

    @media screen and (max-width: 600px) {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 20px;
      padding-top: 80px;
      padding-bottom: 80px;
      background-size: 400%;
      background-image: url("../images/shutterstock_1165708858_m.png");
    }
  }

  &.faq {

    margin: 170px auto 200px;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    grid-column-gap: 40px;
    grid-row-gap: 80px;
    align-items: baseline;
    padding: 0 40px;


    h3 {
      grid-column: 1/-1;
      text-align: center;
    }


    .section_item {
      margin: 0;
    }

    .section_item.fullsize {
      grid-column: 1/-1;
    }

    @media screen and (max-width: 600px) {
      margin-top: 80px;
      margin-bottom: 80px;
      grid-column-gap: 20px;
      grid-row-gap: 40px;

      padding: 0 20px;
    }
  }
}
